// Products.tsx
import React, { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';
import ProductCard from './ProductCard';
import './Products.css';
import { Product } from '../Product';
import products from './../productList.json';
import Modal from './Modal';


const Products: React.FC = () => {
  const productList = JSON.parse(JSON.stringify(products));
  const [selectedBrands, setSelectedBrands] = useState<string[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [searchKey, setSearchKey] = useState('');

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  
  const handleBrandChange = (brand: string) => {
    setSelectedBrands(prevSelectedBrands =>
      prevSelectedBrands.includes(brand)
        ? prevSelectedBrands.filter(b => b !== brand)
        : [...prevSelectedBrands, brand]
    );
  };
  let filteredProducts = productList.products.filter((product: Product) =>
    selectedBrands.length === 0 || selectedBrands.includes(product.brand) 
  );
  const filterList = (e:ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
    //setSearchKey(e.target.value);
    filteredProducts = filteredProducts.filter((product: Product) =>  {   
      console.log(product.productName.toLowerCase());
      return product.productName.toLowerCase().includes(e.target.value.toLocaleLowerCase()) }
    );
  }
  return (
    <div className="container">      
      <div className="row">
        <div className="col-sm-2 pt-5">
        <div>
          <h5><b>Brand:</b></h5>
          <div>
            <input
              type="checkbox"
              id="brand-1"
              checked={selectedBrands.includes('TYNOR')}
              onChange={() => handleBrandChange('TYNOR')}
            /> TYNOR
          </div>
          <div>
            <input
              type="checkbox"
              id="brand-2"
              checked={selectedBrands.includes('FLAMINGO')}
              onChange={() => handleBrandChange('FLAMINGO')}
            /> FLAMINGO
          </div>
        </div>
        </div>
        <div className="col-sm-10">
          <div className='product-container'> 
          <h5>Showing <b>{filteredProducts.filter((fp: Product) => fp.productName.toLowerCase().includes(searchKey.toLowerCase()) || searchKey == '').length}</b> products</h5>   
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <h5>Search:</h5> 
          <input
              type="text"
              id="search"
              value={searchKey}
              onChange={e => setSearchKey(e.target.value)}
            />
            <div className="row">
              {filteredProducts.filter((fp: Product) => fp.productName.toLowerCase().includes(searchKey.toLowerCase()) || searchKey == '').map((product: Product, index: number) => (
                <ProductCard key={index} product={product}/>
              ))}
            </div>
          </div>
        </div>     
      </div>
    </div>   
  );
};

export default Products;