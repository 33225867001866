import React from 'react';
import { Product } from '../Product';
import { useLocation } from 'react-router-dom';

// interface ProductDetailsProps {
//   product: Product;
// }

const ProductDetails: React.FC = () => {
  const location = useLocation();
  const product = location.state ;

  if (!product) {
    return <div>Product not found</div>;
  }
    return (
      <div className="container mt-4">
        <div className="row p-4">
          <div className="col-md-4">
            <img src={product.productUrl} className="img-fluid" alt={product.productName} />
          </div>
          <div className="col-md-8">
            <h1>Name: {product.productName}</h1>
            <h2>Brand: {product.brand}</h2>
            <h3>Category: {product.categoryName}</h3>
            <h3>Sizes: {product.size}</h3>
          </div>
        </div>
      </div>
    );
  };
  
  export default ProductDetails;