// Home.tsx
import React from 'react';

const Home: React.FC = () => {
  return (
    <div className="page">
      <p><b>The Essential Store</b> is a Nagpur based Company.  It is a sole proprietorship. We are a start-up company that will initially distribute a wide range of medical equipments and patient care products at affordable prices.
      </p>
      <div className="container p-3">
        <div className="row pb-4">
          <div className="col-sm">
            <img src="/images/home/commode-chair.png" alt="commode-chair"/>
            <h4>Commode Chair</h4>
          </div>
          <div className="col-sm">
            <img src="/images/home/avm-adult-diapers.png" alt="avm-adult-diapers"/>
            <h4>AVM Adult Diapers</h4>
          </div>
          <div className="col-sm">
            <img src="/images/home/commode-elevator.png" alt="commode-elevator"/>
            <h4>Commode Elevator</h4>
          </div>
        </div>
        <div className="row pb-4">
          <div className="col-sm">
            <img src="/images/home/easycare-airbed.png" alt="easycare-airbed"/>
            <h4>Easycare Airbed</h4>
          </div>
          <div className="col-sm">
            <img src="/images/home/hospifit-diapers.png" alt="hospifit-diapers"/>
            <h4>Hospifit Diapers</h4>
          </div>
          <div className="col-sm">
            <img src="/images/home/semi-flower-bed.png" alt="semi-flower-bed"/>
            <h4>Semi-flower Bed</h4>
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            <img src="/images/home/walker.png" alt="walker"/>
            <h4>Walker</h4>
          </div>
          <div className="col-sm">
            <img src="/images/home/walking-stick.png" alt="walking-stick"/>
            <h4>Walking Stick</h4>
          </div>
          <div className="col-sm">
            <img src="/images/home/whelchair.png" alt="whelchair"/>
            <h4>Wheelchair</h4>
          </div>
        </div>
      </div>
      
      
      
    </div>
  );
};

export default Home;
