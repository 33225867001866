// About.tsx
import React from 'react';

const About: React.FC = () => {
  return (
    <div className="page">
      <h2>About Us</h2>
      <ul>
        <li>Dealer and Distributor of Surgica & Medical Equipments</li>
        <li>All types of OT & ICU Equipments, Oxygen Therapy Equipments, Pipeline & Anaesthesia Accessories,Hospital Furniture, Hospital Hollware, X-Ray Protection Products and Patient Care Products.</li>
        <li>Nagpur, Maharashtra</li>
        <li>All over India</li>
        <li>Our mission is to be the healthcare industry’s first choice for all their surgical instrument requirements and to deliver products of outstanding quality, with great service, at an affordable price.</li>
        <li>Cash Payments and Online Payments accepted</li>
      </ul>
      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7445.470800891039!2d79.1003688!3d21.0832278!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd4bf5924619639%3A0xa2025a9e1794d8eb!2sThe%20Essential%20Store!5e0!3m2!1sen!2sin!4v1720871868438!5m2!1sen!2sin" 
      width="600" height="450" style={{border:0}}  loading="lazy" ></iframe>
    </div>
  );
};

export default About;
