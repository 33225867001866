import React from 'react';
import { Link } from 'react-router-dom';
import './Header.css';

const Header: React.FC = () => {
  return (
    <header className="app-header">
      <h1>The Essential Store</h1>
      <span style={{float: 'right'}}>        
        <a className='whatsapp-link' href="https://wa.me/919890008980?text=I'm%20interested%20in%20your%20products">
        <img src={'/whatsapp.png'} width={30}></img>&nbsp;Contact Us</a>
      </span>
      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/products">Products</Link></li>
          <li><Link to="/about">About</Link></li>          
        </ul>        
      </nav>      
    </header>
  );
};

export default Header;