// src/components/ProductCard.tsx
import React from 'react';
import { Product } from '../Product';
import { Link } from 'react-router-dom';

interface ProductCardProps {
  product: Product;
}
//{product.productUrl}
const ProductCard: React.FC<ProductCardProps> = ({ product }) => {
   // console.log(product);
    const getProductId = (productObject: Product) => {
        const prodUrl = productObject.productUrl;
        const endIndex = prodUrl.indexOf('.');
        const prodId = prodUrl.substring(17, endIndex).replaceAll(/\//gi, '_');
        productObject.id = prodId;
        return prodId;
    }
  return (
    <div className='col-sm-3 p-3' key={product.id}>
        <Link to="/product" state={product}>
            <div className="card border-secondary">            
                <img src={product.productUrl} className="card-img-top" height={150} alt={product.productName}></img>
                <div className="card-body">
                <h5 className="card-title">{product.productName}</h5>
                </div>            
            </div>
        </Link>
    </div>
    
  );
};

export default ProductCard;
