import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Products from './components/Products';
import './App.css';
import ProductDetails from './components/ProductDetails';

const App: React.FC = () => {
  return (
    <Router>
      <Header />
      <div className="app-body">
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/products" element={<Products/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/product" element={<ProductDetails/>} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
